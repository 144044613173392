function App() {
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col items-center justify-center space-y-10 h-full">
        <img src="/logo.svg" alt="Hadirin" className="w-[20rem]" />
        <h1 className="text-4xl">Segera Hadir</h1>
      </div>
    </div>
  );
}

export default App;
